import {
  ABOUT_US,
  CONTACT_US,
  FAQS,
  OTHERS,
  PRIVACY_POLICY,
  RETURN_POLICY,
  TERMS_CONDITIONS,
} from "./routes";

export const CMS_TAB_LINKS = [
  { path: PRIVACY_POLICY.path, label: "Privacy Policy" },
  { path: ABOUT_US.path, label: "About Us" },
  { path: FAQS.path, label: "FAQ's" },
  { path: TERMS_CONDITIONS.path, label: "Terms & Conditions" },
  { path: RETURN_POLICY.path, label: "Return Policy" },
  { path: CONTACT_US.path, label: "Contact Us" },
  { path: OTHERS.path, label: "Others" },
];
export const CONTENT_TYPES = {
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_CONDITIONS: "TERMS_AND_CONDITIONS",
  COPYRIGHT_POLICY: "COPYRIGHT_POLICY",
  COMMUNITY_GUIDELINES: "COMMUNITY_GUIDELINES",
  ABOUT_US: "ABOUT_US",
  DATA_POLICY: "DATA_POLICY",
  FAQS: "FAQ",
};

export const LANGUAGES_VALUE = {
  ARABIC: "ar",
  ENGLISH: "en",
};

export const LANGUAGES = [
  { name: "Arabic", value: LANGUAGES_VALUE.ARABIC },
  { name: "English", value: LANGUAGES_VALUE.ENGLISH },
];

export const THEME = {
  DARK: "dark",
  LIGHT: "light",
};

export const ONBOARDING_KEYS = {
  ID: "id",
  TOKEN: "token",
  DEVICE_TOKEN: "deviceToken",
  DEVICE_ID: "deviceId",
  NEW_PASSWORD: "newPassword",
  ENCRYPTED_TOKEN: "encryptedToken",
  STRING: "string",
};

export const STATIC_CONTENT_CONST = {
  DESCRIPTION_EN: "descriptionEn",
  DESCRIPTION_AR: "descriptionAr",
  QUESTION_AR: "questionAr",
  QUESTION_EN: "questionEn",
  ANSWER_AR: "answerAr",
  ANSWER_EN: "answerEn",
  CONTENT_TYPE: "contentType",
  CREATED: "created",
  FAQS_ID: "faqId",
};

export const COMMON_KEYS = {
  LANGUAGE: "language",
  CMS_PAGE: "cmsPage",
  THEME: "theme",
};

/**
 * @STATUS_OPTIONS
 */
export const STATUS_OPTIONS = [
  {
    id: 1,
    label: "Active",
    value: "ACTIVE",
  },
  {
    id: 2,
    label: "Blocked",
    value: "BLOCKED",
  },
];
/**
 * @DASHBOARD_IMPORTANT_STATISTICS
 */
export const IMPORTANT_STATISTICS = [
  {
    url: "assets/images/svg/inactive-users.svg",
    title: "Inactive Users",
    data: 0,
  },
  {
    url: "assets/images/svg/active-users.svg",
    title: "Active Users",
    data: 0,
  },
];
export const CHALLENGES_STATISTICS = [
  {
    url: "assets/images/svg/self.svg",
    title: "Self",
    data: 0,
  },
  {
    url: "assets/images/svg/one-v-one.svg",
    title: "One-V-One",
    data: 0,
  },
  {
    url: "assets/images/svg/group.svg",
    title: "Group",
    data: 0,
  },
  {
    url: "assets/images/svg/pool.svg",
    title: "Pool",
    data: 0,
  },
  //  {
  //   url: "assets/images/svg/marathon.svg",
  //   title: "Marathon",
  //   data: 0,
  // },
];
export const PAYMENT_STATISTICS = [
  {
    url: "assets/images/svg/payin-completed.svg",
    title: "PayIn Completed",
    data: 0,
  },
  {
    url: "assets/images/svg/payin-failed.svg",
    title: "PayIn Failed",
    data: 0,
  },
  {
    url: "assets/images/svg/payout-completed.svg",
    title: "Payout Completed",
    data: 0,
  },
  {
    url: "assets/images/svg/payout-failed.svg",
    title: "Payout Failed",
    data: 0,
  },
  {
    url: "assets/images/svg/payout-process.svg",
    title: "Payout Process",
    data: 0,
  },
];
export const GENDER_DROPDOWN = [
  { label: "Any", value: "Any" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  // {label:"Other",value:'OTHER'},
];
export const REWARD_DISTRIBUTION = [
  { label: "Winner takes it all" },
  { label: "Relative distribution" },
];

export const BMI_DROPDOWN = [
  { label: "Any", value: "Any" },
  { label: "Underweight", value: "underweight" },
  { label: "Athlete", value: "athlete" },
  { label: "Normal", value: "normal" },
  { label: "Overweight", value: "overweight" },
  { label: "Obese", value: "obese" },
];
export const LEVEL_DROPDOWN = [
  { label: "Slower", value: "Fit" },
  { label: "Regular", value: "Fitter" },
  { label: "Faster", value: "Fittest" },
];

export const AGE_DROPDOWN = [
  { label: "Any", value: "Any" },
  { label: "18-25", value: "18-25" },
  { label: "26-40", value: "26-40" },
  { label: "41-50", value: "41-50" },
  { label: "50+", value: "Above50" },
];
export const checkEmail = "paritoshd@gmail.com";
export const PLATFORM_DROPDOWN = [
  { value: "Android", label: "Android" },
  { value: "iOS", label: "iOS" },
];
export const STATUS_DROPDOWN = [
  { value: 0, label: "Active" },
  { value: 1, label: "Block" },
];
export const distanceLabels = [
  "Distance (BMI: <18)*(Consumption: <2)",
  "Distance (BMI: <18)*(Consumption: 2-5)",
  "Distance (BMI: <18)*(Consumption: 5+)",
  "Distance (BMI: <18-24)*(Consumption: <2)",
  "Distance (BMI: <18-24)*(Consumption: 2-5)",
  "Distance (BMI: <18-24)*(Consumption: 5+)",
  "Distance (BMI: <24-29)*(Consumption: <2)",
  "Distance (BMI: <24-29)*(Consumption: 2-5)",
  "Distance (BMI: <24-29)*(Consumption: 5+)",
  "Distance (BMI: 29+)*(Consumption: <2)",
  "Distance (BMI: 29+)*(Consumption: 2-5)",
  "Distance (BMI: 29+)*(Consumption: 5+)",
];

export const definedAge = [
  { label: "Age(18 to 25)", value: "18to25" },
  { label: "Age(26 to 40)", value: "26to40" },
  { label: "Age(41 to 55)", value: "41to55" },
  { label: "Age(55 plus)", value: "55plus" },
];

export const prizeDistributionLabel = [
  "Qualified users % for (0-100) participants",
  "Qualified users % for (101-1000) participants",
  "Qualified users % for (1001-2500) participants",
  "Qualified users % for (2501-5000) participants",
  "Qualified users % for (5000+) participants",
];
export const formControlNames={
  PRIZE_DISTRIBUTION:'pertOfQualifiedUsersGroup',
  DISTANCE:'distance'
}
export const selfChallenge =[
  {label:'Number of self challenges for pro users',value:'selfChallengeCountForPro'},
  {label:'Number of self challenges for elite users',value:'selfChallengeCountForElite'},
]

export const maxRM =[
  {label:'Last self won max RM for slower',value:'lastSelfWonMaxRmForFit'},
  {label:'Last self won max RM for regular',value:'lastSelfWonMaxRmForFitter'},
  {label:'Last self won max RM for faster',value:'lastSelfWonMaxRmForFittest'},
  {label:'Last self lost max RM for slower',value:'lastSelfLostMaxRmForFit'},
  {label:'Last self lost max RM for regular',value:'lastSelfLostMaxRmForFitter'},
  {label:'Last self lost max RM for faster',value:'lastSelfLostMaxRmForFittest'},
  {label:'Last multi won max RM for slower',value:'lastMultiWonMaxRmForFit'},
  {label:'Last multi won max RM for regular',value:'lastMultiWonMaxRmForFitter'},
  {label:'Last multi won max RM for faster',value:'lastMultiWonMaxRmForFittest'},
  {label:'Last multi lost max RM  for slower',value:'lastMultiLostMaxRmForFit'},
  {label:'Last multi lost max RM for regular',value:'lastMultiLostMaxRmForFitter'},
  {label:'Last multi lost max RM for faster',value:'lastMultiLostMaxRmForFittest'},
  {label:'First 5 max RM  for slower',value:'remainingFreeSelfMaxRmForFit'},
  {label:'First 5 max RM for regular',value:'remainingFreeSelfMaxRmForFitter'},
  {label:'First 5 max RM for faster',value:'remainingFreeSelfMaxRmForFittest'},
]
export const maxBA =[
  {label:'Last self won BA for regular',value:'lastSelfWonMaxBaForFitter'},
  {label:'Last self won BA for faster',value:'lastSelfWonMaxBaForFittest'},
  {label:'Last self lost BA for regular',value:'lastSelfLostMaxBaForFitter'},
  {label:'Last self lost BA for faster',value:'lastSelfLostMaxBaForFittest'},
  {label:'Last multi won BA for regular',value:'lastMultiWonMaxBaForFitter'},
  {label:'Last multi won BA for faster',value:'lastMultiWonMaxBaForFittest'},
  {label:'Last multi lost BA for regular',value:'lastMultiLostMaxBaForFitter'},
  {label:'Last multi lost BA for faster',value:'lastMultiLostMaxBaForFittest'},
  {label:'First 5 BA for regular',value:'remainingFreeSelfMaxBaForFitter'},
  {label:'First 5 BA for faster',value:'remainingFreeSelfMaxBaForFittest'},
]
export const hrOfActivityScore =[
  {label:'Heart rate for last self won',value:'lastSelfWonHrOfActivityScore'},
  {label:'Heart rate for last self lost',value:'lastSelfLostHrOfActivityScore'},
  {label:'Heart rate for last multi won',value:'lastMultiWonHrOfActivityScore'},
  {label:'Heart rate for last multi lost',value:'lastMultiLostHrOfActivityScore'},
  {label:'Heart rate for first 5',value:'remainingFreeSelfHrOfActivityScore'},
]
export const walking4Times =[
  {label:'Age(18 to 25)',value:'walkingFrequency4TimesAWeek18to25'},
  {label:'Age(26 to 40)',value:'walkingFrequency4TimesAWeek26to40'},
  {label:'Age(41 to 55)',value:'walkingFrequency4TimesAWeek41to55'},
  {label:'Age(55+)',value:'walkingFrequency4TimesAWeek55plus'},
]
export const walkingTwoWeeks =[
  {label:'Age(18 to 25)',value:'walkingFrequencyOnceIn2Weeks18to25'},
  {label:'Age(26 to 40)',value:'walkingFrequencyOnceIn2Weeks26to40'},
  {label:'Age(41 to 55)',value:'walkingFrequencyOnceIn2Weeks41to55'},
  {label:'Age(55+)',value:'walkingFrequencyOnceIn2Weeks55plus'},
]
export const walkingMonth =[
  {label:'Age(18 to 25)',value:'walkingFrequencyOnceInAMonth18to25'},
  {label:'Age(26 to 40)',value:'walkingFrequencyOnceInAMonth26to40'},
  {label:'Age(41 to 55)',value:'walkingFrequencyOnceInAMonth41to55'},
  {label:'Age(55+)',value:'walkingFrequencyOnceInAMonth55plus'},
]
export const running4Times =[
  {label:'Age(18 to 25)',value:'runningFrequency4TimesAWeek18to25'},
  {label:'Age(26 to 40)',value:'runningFrequency4TimesAWeek26to40'},
  {label:'Age(41 to 55)',value:'runningFrequency4TimesAWeek41to55'},
  {label:'Age(55+)',value:'runningFrequency4TimesAWeek55plus'},
]
export const runningTwoWeeks =[
  {label:'Age(18 to 25)',value:'runningFrequencyOnceIn2Weeks18to25'},
  {label:'Age(26 to 40)',value:'runningFrequencyOnceIn2Weeks26to40'},
  {label:'Age(41 to 55)',value:'runningFrequencyOnceIn2Weeks41to55'},
  {label:'Age(55+)',value:'runningFrequencyOnceIn2Weeks55plus'},
]
export const runningMonth =[
  {label:'Age(18 to 25)',value:'runningFrequencyOnceInAMonth18to25'},
  {label:'Age(26 to 40)',value:'runningFrequencyOnceInAMonth26to40'},
  {label:'Age(41 to 55)',value:'runningFrequencyOnceInAMonth41to55'},
  {label:'Age(55+)',value:'runningFrequencyOnceInAMonth55plus'},
]
export const multi4Times =[
  {label:'Age(18 to 25)',value:'mixFrequency4TimesAWeek18to25'},
  {label:'Age(26 to 40)',value:'mixFrequency4TimesAWeek26to40'},
  {label:'Age(41 to 55)',value:'mixFrequency4TimesAWeek41to55'},
  {label:'Age(55+)',value:'mixFrequency4TimesAWeek55plus'},
]
export const multiTwoWeeks =[
  {label:'Age(18 to 25)',value:'mixFrequencyOnceIn2Weeks18to25'},
  {label:'Age(26 to 40)',value:'mixFrequencyOnceIn2Weeks26to40'},
  {label:'Age(41 to 55)',value:'mixFrequencyOnceIn2Weeks41to55'},
  {label:'Age(55+)',value:'mixFrequencyOnceIn2Weeks55plus'},
]
export const multiMonth =[
  {label:'Age(18 to 25)',value:'mixFrequencyOnceInAMonth18to25'},
  {label:'Age(26 to 40)',value:'mixFrequencyOnceInAMonth26to40'},
  {label:'Age(41 to 55)',value:'mixFrequencyOnceInAMonth41to55'},
  {label:'Age(55+)',value:'mixFrequencyOnceInAMonth55plus'},
]

// export const refSpeed =[
//   {label:'Last self won Ref speed for slower',value:'lastSelfWonRefSpeedFit'},
//   {label:'Last self won Ref speed  for regular',value:'lastSelfWonRefSpeedFitter'},
//   {label:'Last self won Ref speed  for faster',value:'lastSelfWonRefSpeedFittest'},
//   {label:'Last self lost Ref speed for slower',value:'lastSelfLostRefSpeedFit'},
//   {label:'Last self lost Ref speed for regular',value:'lastSelfLostRefSpeedFitter'},
//   {label:'Last self lost Ref speed for faster',value:'lastSelfLostRefSpeedFittest'},
//   {label:'Last multi won Ref speed for slower',value:'lastMultiWonRefSpeedFit'},
//   {label:'Last multi won Ref speed for regular',value:'lastMultiWonRefSpeedFitter'},
//   {label:'Last multi won Ref speed for faster',value:'lastMultiWonRefSpeedFittest'},
//   {label:'Last multi lost Ref speed  for slower',value:'lastMultiLostRefSpeedFit'},
//   {label:'Last multi lost Ref speed for regular',value:'lastMultiLostRefSpeedFitter'},
//   {label:'Last multi lost Ref speed for faster',value:'lastMultiLostRefSpeedFittest'},
// ]

export const FEEDBACK_ENUM = {
  TOO_EASY: "TOO_EASY",
  PERFECT: "PERFECT",
  TOO_HARD: "TOO_HARD",
  TOO_LITTLE: "TOO_LITTLE",
  WELL_DESERVING: "WELL_DESERVING",
  VERY_GENEROUS: "VERY_GENEROUS"
};