const USER_BASE: string = 'api/v1';
const ADMIN_BASE: string = 'api/v1/admin';

export const API_URL = {
  LOGIN : `/login`,
  LOGOUT: `/logout`,
  PROFILE: `/profile`,
  UPDATE_PROFILE: `/updateProfile` ,
  CHANGE_PASSWORD: `/changePassword`,
  FORGOT_PASSWORD : `/forgotPassword`,
  RESET_PASSWORD : `/resetPassword`,
  GET_CMS : (type) =>`/cms?contentType=${type}`,
  UPDATE_CMS : `/updateContent`,
  DELETE_FAQ : (id) =>`/deleteFaq/${id}`,
  CITY_STATE_SEARCH: `/citySearch`,
  // Users Api
  USERS : `/userList`,
  BLOCK_UNBLOCK_USER : `/userBlock`,
  DELETE_USER : `/userDelete`,
  USER_DETAILS : `/userDetails`,
  UPDATE_CHALLENGE:`/updateMonitor`,
  CONTROL:`/control`,
  UPDATE_CONTROL:`/updateControl`,
  CREATE_CHALLENGE:`/createChallenge`,
  CHALLENGE_LIST:`/challengeList?`,
  DELETE_CHALLENGE:(id)=>`/deleteChallenge/${id} `,
  CHALLENGE_DETAILS:(id)=> `/challengeDetails?challengeId=${id} `,
  EDIT_CHALLENGE:`/editChallenge`,
  PARTICPATE_LIST: `/participateList`,
  UPDATE_INDIVIDUAL_SPEED: `/editSpeed`,
  ANALYTICS:`/analytics`,
  VERSION_LIST:`/versionList`,
  DELETE_VERSION:(id)=>`/deleteVersion/${id}`,
  EDIT_VERSION:`/editVersion`,
  ADD_VERSION:`/addVersion`,
  MONITOR_LIST:`/monitorList`,
  DELETE_MONITOR:(id)=>`/deleteMonitor/${id}`,
  CRON_API:`/resultCal`,
  ADD_COUPON:'/addCoupon',
 EDIT_COUPON:'/editCoupon',
 COUPON_LIST:'/couponList',
 INACTIVE_COUPON:'/inactiveCoupon'
}

/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `${ADMIN_BASE}/login`;
export const LOGOUT_API = `${ADMIN_BASE}/logout`;
export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgot-password`;
export const PROFILE = `${ADMIN_BASE}/profile`;
export const RESET_PASSWORD_API = `${ADMIN_BASE}/reset-password`;
export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/change-password`;

/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD:string = `${ADMIN_BASE}/dashboard`;

/**
 * @CMS_RELATED_END_POINTS
 */
export const STATIC_CONTENT_FAQS: string = `${ADMIN_BASE}/faq`;
/**
 * @MEDIA_RELATED_END_POINTS
 */
export const MEDIA_UPLOAD:string = `${USER_BASE}/media-upload`;


/**
 * @NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION:string = `${ADMIN_BASE}/notification`;
